import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "./section"
import Pattern from "./pattern"
import Img from "gatsby-image"
import ModalVideo from 'react-modal-video'

import '../../node_modules/react-modal-video/scss/modal-video.scss'

const featureSectionHeader = (text) => (
  <div className="text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl my-16 md:my-20 text-indigo-600">
    {text}
  </div>
)


const PlayButton = () => <svg height="160" className="absolute z-50 opacity-75 cursor-pointer" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 122.88">
  <g fillRule="evenodd" clipRule="evenodd">
    <path fill="#eee" d="M61.44,0c33.93,0,61.44,27.51,61.44,61.44s-27.51,61.44-61.44,61.44S0,95.37,0,61.44S27.51,0,61.44,0L61.44,0z M83.31,65.24c3.13-2.02,3.12-4.27,0-6.06L50.98,40.6c-2.55-1.6-5.21-0.66-5.14,2.67l0.1,37.55c0.22,3.61,2.28,4.6,5.32,2.93 L83.31,65.24L83.31,65.24z M61.44,12.48c27.04,0,48.96,21.92,48.96,48.96c0,27.04-21.92,48.96-48.96,48.96S12.48,88.48,12.48,61.44 C12.48,34.4,34.4,12.48,61.44,12.48L61.44,12.48z"/>
  </g>
</svg>

const FeatureLeft = (props) => {
  const [isOpen, setOpen] = useState(false)

  return <div className="relative mt-24 sm:grid sm:grid-cols-2 sm:gap-8 sm:items-center" id={props.id}>
    {props.youtubeID && <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.youtubeID} onClose={() => setOpen(false)} />}
    <div className="relative">
      <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
        {props.title}
      </h4>
      <div className="mt-4 text-lg leading-7 text-gray-500">
        <span className="mt-6 text-xl font-medium block">
          {props.description}
        </span>
        <span className="mt-4 font-light block">
          <a href={"/media-pack/" + encodeURIComponent(props.filename)} className="underline" download>Download</a>
        </span>

        {props.youtubeID && (<div>
          <a
            href="/"
            className="mt-2 inline-flex items-center justify-center py-1 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out text-blue-800 hover:text-blue-600 hover:underline"
            id={`features-${props.id}-watch-movie-link`}
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }
            }
          >
            Watch movie &raquo;
          </a>
        </div>)}
      </div>
    </div>

    <div className="mt-10 sm:mt-0 w-full flex flex-col justify-start">
      <div className="hidden sm:block">
      </div>
      <div
        id={`features-${props.id}-watch-movie-img`}
        className="w-full flex flex-row justify-center items-center"
      >
        {props.youtubeID && <PlayButton />}
        <a href={"/media-pack/" + encodeURIComponent(props.filename)}>
          <img src={"/media-pack/" + encodeURIComponent(props.filename)} className={"mx-auto shadow-xl hover:shadow-2xl rounded-lg cursor-pointer w-1/2 object-cover " + props.className} alt="" />
        </a>
      </div>
    </div>
    <div className="mt-24 w-full flex flex-row justify-center sm:hidden ">
      <hr className="w-1/2" />
    </div>
  </div>
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg|png)/i" }
          relativeDirectory: { eq: "images" }
        }
      ) {
        edges {
          node {
            id
            name
            relativeDirectory
            extension
            
            childImageSharp {
              fixed(width: 500, quality: 100) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="py-16 bg-gray-50 overflow-hidden sm:py-24" id="features">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 sm:px-8 sm:max-w-screen-xl">

          <div className="relative">
            <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Media Pack
            </h3>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
              Here are some assets to use when you talk about Meet Cam.
            </p>
          </div> 

          <FeatureLeft
            id="app-icon-large"
            title="App Icon Large"
            filename="App Icon Large.png"
            description={[
              'Transparent PNG'
            ]}
          />

          <FeatureLeft
            id="app-icon"
            title="App Icon"
            filename="App Icon.png"
            description={[
              'Transparent PNG'
            ]}
          />

          <FeatureLeft
            id="app-logo-black"
            title="App Logo Black"
            description={[
              'Transparent PNG'
            ]}
            filename="MeetCam-Logo-Black.png"
          />

          <FeatureLeft
            id="app-logo-white"
            title="App Logo White"
            className="bg-gray-300"
            description={[
              'Transparent PNG'
            ]}
            filename="MeetCam-Logo-White.png"
          />

          <FeatureLeft
            id="meet-cam-ui"
            title="Meet Cam UI"
            description={[
              'JPEG'
            ]}
            filename="Meet Cam UI.jpg"
          />

          <FeatureLeft
            id="meet-cam-menu-bar"
            title="Meet Cam Menu Bar"
            description={[
              'JPEG'
            ]}
            filename="Meet Cam Menu Bar.jpg"
          />

          <FeatureLeft
            id="meet-cam-google-meet"
            title="Meet Cam meets Google Meet"
            description={[
              'JPEG'
            ]}
            filename="Meet Cam meets Google Meet.jpg"
          />

          <FeatureLeft
            id="zip"
            title="All Assets"
            description='ZIP Archive (13 MB)'
            filename="Meet Cam Media Pack.zip"
          />
        </div>
      </div>
    </Section>
  )
}
