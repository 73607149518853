import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import MainHero from '../components/mainHero';
import MediaPack from '../components/mediaPack';
import Footer from '../components/footer';
import logo from '../data/images/logo.gif';

const Page = ({ data }) => (
  <Layout>
    <SEO
      title="Meet Cam: make your online meetings productive and fun!"
      description="Meet Cam adds useful (like timers and agenda) and fun widgets (emojis and gifs!) to your camera feed for everyone to see next to you on the call."
    />

    <div className="max-h-10perc flex flex-row justify-center mt-20">
      <img src={logo} className="max-h-10perc" alt="Meet Cam logo" />
    </div>

    <MediaPack />
    <Footer />
  </Layout>
);

export const pageQuery = graphql`
  query MediaPackQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Page;
